import { Component, Input } from '@angular/core';
import { SharedModule } from '../../../../shared.module';
import { ASSESSMENT_COLS } from '../../../../constants';
import { TableCols } from '../../../../types';
import { ProjectService } from '../../project.services';
import { TooltipModule } from 'primeng/tooltip';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import JSZip from 'jszip';
import { ButtonComponent } from '../../../../Components/button/button.component';

@Component({
  selector: 'app-manage-field-type',
  standalone: true,
  imports: [SharedModule, TooltipModule, ButtonComponent],
  templateUrl: './manage-field-type.component.html',
  styleUrl: './manage-field-type.component.scss',
})
export class ManageFieldTypeComponent {
  cols: TableCols[] = ASSESSMENT_COLS;
  @Input() data: any[] = [];
  @Input() name: string = '';
  Boolean: any[] = [
    { name: 'Yes', key: 'yes' },
    { name: 'No', key: 'no' },
  ];
  PicklistData: any[] = [];
  selectedFiles: File[] = [];
  @Input() isLoadingField: boolean = false;
  skeletonRows = new Array(5);
  originalImageUrl: string = '';
  imageDialog: boolean = false;
  isLoading: boolean = false;

  constructor(private service: ProjectService, private http: HttpClient) {}

  async displayImage(rowData: any) {
    this.isLoading = true;
    this.imageDialog = true;
    const headers = new HttpHeaders({
      Accept: 'application/zip',
    });
    const payload = { ImagePaths: [rowData] };
    try {
      const response: any = await this.http
        .post(`${environment.baseUrl}/api/images/fetch`, payload, {
          headers,
          responseType: 'arraybuffer',
        })
        .toPromise();

      const zip = new JSZip();
      await zip.loadAsync(response);
      const imagePromises = Object.keys(zip.files).map(async (filename) => {
        const file = zip.files[filename];
        const blob = await file.async('blob');
        const url = URL.createObjectURL(blob);
        return url;
      });

      const images = await Promise.all(imagePromises);

      this.originalImageUrl = images[0];
      if (images) this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  closeDialog(): void {
    this.imageDialog = false;
    this.originalImageUrl = '';
  }

  async onFileSelected(event: Event, rowData: any): Promise<void> {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }

    const files = Array.from(input.files);
    this.selectedFiles = files;

    rowData.value = [];
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        rowData.value.push(e.target.result);
      };
      reader.readAsDataURL(file);
    });

    await this.uploadImageValue(rowData);
  }

  async uploadImageValue(rowData: any): Promise<void> {
    const formData = new FormData();
    this.selectedFiles.forEach((file) => {
      formData.append('Images', file, file.name);
    });
    formData.append('JobFieldId', rowData.id.toString());
    try {
      const res = await this.service.createJobFieldsImage(formData);
    } catch (error: any) {}
  }
}
