import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CatalogueService {
  constructor(private api: ApiService, private http: HttpClient) {}

  async fetchCatalogueData(pageNo: number, pageSize: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/catalogue?pageNumber=${pageNo}&pageSize=${pageSize}`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchPriceData(): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/catalogue?fetchOnlyFavourites=true`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchCatalogueCount(): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get('api/catalogue/count'));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async searchCatalogueData(
    pageNo: number,
    pageSize: number,
    val: string
  ): Promise<any> {
    try {
      const payload = {
        searchText: val,
      };
      const data = await firstValueFrom(
        this.api.post(
          `api/catalogue/search?pageNumber=${pageNo}&pageSize=${pageSize}`,
          payload
        )
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateCatalogueData(
    pageNo: number,
    pageSize: number,
    val: any
  ): Promise<any> {
    try {
      const response = await lastValueFrom(
        this.http.put(
          `${environment.baseUrl}/api/catalogue?pageNumber=${pageNo}&pageSize=${pageSize}`,
          [val],
          {
            responseType: 'text',
          }
        )
      );

      if (typeof response === 'string') {
        return response;
      }

      throw new Error('Unexpected response format from server');
    } catch (error) {
      throw error;
    }
  }

  async fetchServiceCatalogueData(): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/servicecatalogue/installer`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createServiceCatalogueData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(`api/servicecatalogue`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateServiceCatalogueData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.put(`api/servicecatalogue/${payload.id}`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteServiceCatalogueData(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.delete(`api/servicecatalogue/${id}`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
