<div class="page-container profile-container">
  <p-toast />
  <div style="padding-top: 2rem">
    <p-tabView>
      <p-tabPanel header="My Profile">
        <form [formGroup]="profileData" class="profile">
          <div class="image-container">
            <div class="profile-picture-container" (click)="profileImg.click()">
              <img
                class="profile-image"
                [src]="profileImage || 'assets/Profile Icon 1.svg'"
              />
              <input
                type="file"
                #profileImg
                (change)="onProfileImage($event)"
                accept="image/*"
                style="display: none"
              />
              <img src="assets/profileEdit.icon.svg" class="edit-icon" />
            </div>
          </div>
          <div class="form-container">
            <app-input
              label="Name"
              [control]="profileData.get('name')"
              [required]="true"
            />
          </div>
        </form>
        <!-- <div class="profile-footer">
          <app-button
            label="Save"
            [disabled]="profileData.invalid"
            (clickEvent)="onProfileSave()"
          />
        </div> -->
      </p-tabPanel>
      <p-tabPanel header="Company Profile">
        <form [formGroup]="companyData" class="company-container">
          <div class="image-container">
            <div class="company-picture-container" (click)="companyImg.click()">
              <img
                class="company-image"
                [src]="companyImage || 'assets/Profile Icon 1.svg'"
              />
              <input
                type="file"
                #companyImg
                (change)="onCompanyImage($event)"
                accept="image/*"
                style="display: none"
              />
              <img src="assets/profileEdit.icon.svg" class="edit-icon" />
            </div>
          </div>
          <div class="form-container">
            <app-disabled-input
              label="Company Name"
              [control]="companyData.get('company_name')"
              [required]="true"
            />
            <app-input
              label="Email"
              [control]="companyData.get('email')"
              [required]="true"
              errormsg="Entre a valid Email"
            />
            <div style="padding-bottom: 1rem">
              <label class="input-label">
                Billing Address
                <span class="required-label">*</span>
              </label>
              <p-autoComplete
                formControlName="billingAddress"
                [suggestions]="filteredAddress"
                optionLabel="address.freeformAddress"
                (completeMethod)="onSearchAddress($event)"
                [appendTo]="'body'"
              />

              <div
                class="error-message"
                *ngIf="
                  companyData.get('billingAddress')?.invalid &&
                  companyData.get('billingAddress')?.touched
                "
              >
                Billing Address is required.
              </div>
            </div>
            <div style="padding-bottom: 1rem">
              <label class="input-label">
                Business Address
                <span class="required-label">*</span>
              </label>
              <p-autoComplete
                formControlName="businessAddress"
                [suggestions]="filteredAddress"
                optionLabel="address.freeformAddress"
                (completeMethod)="onSearchAddress($event)"
                [appendTo]="'body'"
              />

              <div
                class="error-message"
                *ngIf="
                  companyData.get('businessAddress')?.invalid &&
                  companyData.get('businessAddress')?.touched
                "
              >
                Billing Address is required.
              </div>
            </div>

            <div style="display: flex; gap: 0.5rem; align-items: center">
              <div style="width: 80%">
                <app-input
                  label="ABN"
                  [control]="companyData.get('ABN')"
                  [required]="true"
                />
              </div>
              <div style="margin-top: 0.5rem">
                <app-button
                  label="Search"
                  (clickEvent)="onEnter(companyData.get('ABN')?.value)"
                />
              </div>
            </div>

            <div>
              <label class="input-label">
                GST
                <span class="required-label">*</span>
              </label>
              <div style="display: flex; gap: 2rem; padding-top: 0.4rem">
                <div *ngFor="let data of Boolean">
                  <p-radioButton
                    [inputId]="data.key"
                    [value]="data.name"
                    formControlName="GST"
                  />
                  <label [for]="data.key" class="ml-2">
                    {{ data.name }}
                  </label>
                </div>
              </div>
              <div
                class="error-message"
                *ngIf="
                  companyData.get('GST')?.invalid &&
                  companyData.get('GST')?.touched
                "
              >
                Notes is required.
              </div>
            </div>
          </div>
        </form>
        <div class="profile-footer">
          <app-button
            label="Update"
            [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
            [disabled]="companyData.invalid"
            (clickEvent)="onCompanySave()"
          />
          <app-button
            label="Cancel"
            className="cancel-dialog"
            style="margin-right: 1rem"
            (clickEvent)="onCancel()"
          />
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
