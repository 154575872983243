import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { NavigationExtras, Router } from '@angular/router';
import ROUTES from '../../routes.const';
import { ButtonComponent } from '../../Components/button/button.component';
import { UserProfileService } from '../../Pages/profile-settings/userProfile.service';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';
import { ProjectService } from '../../Pages/project-management/project.services';
import { MessageService } from 'primeng/api';
import JSZip from 'jszip';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [SharedModule, ButtonComponent, SearchFieldComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  providers: [MessageService],
})
export class HeaderComponent {
  onHover: number = 0;
  userName: string | null = '';
  projectId: number = 0;
  searchText: string = '';
  onJobDetailsLoad: boolean = false;
  jobData: any;
  projectData: any[] = [];
  profilePic: any;

  constructor(
    private router: Router,
    private userService: UserProfileService,
    private messageService: MessageService,
    private service: ProjectService,
    private http: HttpClient,
    private authService: MsalService
  ) {}

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  async ngOnInit() {
    await this.getUserData();
  }

  async getJobData(): Promise<any[]> {
    this.onJobDetailsLoad = true;
    try {
      const data = await this.service.searchForJob(this.searchText.trim());

      if (data) {
        await this.getProjectData(data.projectId);
        const params: NavigationExtras = {
          state: { jobData: data, projectData: this.projectData },
        };
        this.router.navigate([ROUTES.JOB_DETAILS], params);
        this.onJobDetailsLoad = false;
      }

      return data;
    } catch (error: any) {
      this.onJobDetailsLoad = false;
      this.errorToast(error.error.message);
      return [];
    }
  }

  async handleProfileEdit(): Promise<void> {
    const data = await this.getUserData();
    const params: NavigationExtras = { state: { data } };
    this.router.navigate([ROUTES.PROFILE_SETTINGS], params);
  }

  async getProjectData(projectId: number): Promise<any[]> {
    try {
      const data = await this.service.getByProjectId(projectId);

      if (data) {
        this.projectData = data;
      }
      return data.jobs;
    } catch (error: any) {
      return [];
    }
  }

  async getUserData() {
    try {
      const data = await this.userService.fetchUserProfileData();
      if (data.users[0].profileImagePath) {
        const imageData = await this.downloadAndExtractImages(data);
        this.profilePic = imageData[0].images[0];
      }
      this.userName = data.name;
      return data;
    } catch (error: any) {
      if (error.status === 0) {
        this.handleLogout();
      }
    }
  }

  handleLogout(): void {
    this.authService.logoutRedirect();
    localStorage.clear();
  }

  jobSearch(searchVal: any): void {
    this.searchText = searchVal;
  }

  async onSearchJob(): Promise<void> {
    await this.getJobData();
  }

  private async downloadAndExtractImages(imageGroups: any): Promise<any> {
    const headers = new HttpHeaders({
      Accept: 'application/zip',
    });

    const result: any = [];

    const payload = { ImagePaths: [imageGroups.users[0].profileImagePath] };

    try {
      const response: any = await this.http
        .post(`${environment.baseUrl}/api/images/fetch`, payload, {
          headers,
          responseType: 'arraybuffer',
        })
        .toPromise();

      const zip = new JSZip();
      await zip.loadAsync(response);
      const imagePromises = Object.keys(zip.files).map(async (filename) => {
        const file = zip.files[filename];
        const blob = await file.async('blob');
        const url = URL.createObjectURL(blob);
        return url;
      });

      const images = await Promise.all(imagePromises);

      result.push({
        images: images,
      });
    } catch (error) {
      console.error(error);
    }

    return result;
  }
}
