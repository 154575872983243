import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { TabViewModule } from 'primeng/tabview';
import { InputComponent } from '../../Components/input/input.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ButtonComponent } from '../../Components/button/button.component';
import { UserProfileService } from './userProfile.service';
import { ApiService } from '../../Services/common-services.service';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { DisabledInputComponent } from '../../Components/disabled-input/disabled-input.component';
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
  HttpHeaders,
} from '@angular/common/http';
import JSZip from 'jszip';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-profile-settings',
  standalone: true,
  providers: [MessageService, ApiService],
  templateUrl: './profile-settings.component.html',
  styleUrl: './profile-settings.component.scss',
  imports: [
    SharedModule,
    TabViewModule,
    InputComponent,
    ButtonComponent,
    DisabledInputComponent,
    HttpClientJsonpModule,
    HttpClientModule,
  ],
})
export class ProfileSettingsComponent {
  profileImage: string | ArrayBuffer | null = null;
  companyImage: string | ArrayBuffer | null = null;
  profileData!: FormGroup;
  companyData!: FormGroup;
  filteredAddress: any[] = [];
  userData: any;
  onSaveLoad: boolean = false;
  Boolean: any[] = [
    { name: 'Yes', key: 'yes' },
    { name: 'No', key: 'no' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private service: UserProfileService,
    private api: ApiService,
    private messageService: MessageService,
    private http: HttpClient
  ) {
    this.initializeProfileForms();
    this.initializeCompanyForms();
  }

  async ngOnInit() {
    await this.getUserData();
  }

  async getUserData() {
    try {
      const data = await this.service.fetchUserProfileData();
      if (data.logoPath) {
        const imageData = await this.downloadAndExtractImages(data.logoPath);
        this.companyImage = imageData[0].images[0];
      }
      if (data.users[0].profileImagePath) {
        const profilePic = await this.downloadAndExtractImages(
          data.users[0].profileImagePath
        );
        this.profileImage = profilePic[0].images[0];
      }

      this.userData = data;
      this.prefillUserData(data);
      return data;
    } catch (error: any) {
      console.log(error);
    }
  }

  private prefillUserData(userData: any): void {
    this.profileData.patchValue({
      name: userData.name,
    });
    this.companyData.patchValue({
      company_name: userData.companyName,
      email: userData.email,
      billingAddress: userData.billingAddress.freeformAddress,
      businessAddress: userData.businessAddress.freeformAddress,
      ABN: userData.abn,
      GST: userData.gst ? 'Yes' : 'No',
    });
  }

  initializeProfileForms(): void {
    this.profileData = this.formBuilder.group({
      image: [''],
      name: ['', Validators.required],
    });
  }

  onSearchAddress(event: any): void {
    this.api.searchAddress(event.query).subscribe(
      (res: any) => {
        this.filteredAddress = res.results;
      },
      (error: any) => {
        console.error('Error fetching address:', error);
      }
    );
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  initializeCompanyForms(): void {
    this.companyData = this.formBuilder.group({
      image: [''],
      company_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      billingAddress: ['', Validators.required],
      businessAddress: ['', Validators.required],
      ABN: ['', Validators.required],
      GST: [''],
    });
  }

  async onProfileImage(event: any) {
    const file: File = event.target.files[0];
    this.profileData.patchValue({ image: file });
    await this.uploadProfileImage();
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.profileImage = reader.result;
      };
    }
  }

  async abnDetails(val: number) {
    const data = await lastValueFrom(this.api.getAbnData(val));
    return data;
  }

  async onEnter(event: any): Promise<void> {
    const data = await this.abnDetails(event);

    if (data.Message) {
      this.errorToast(data.Message);
    } else {
      this.companyData.patchValue({
        company_name: data.EntityName,
      });
    }
  }

  async onCompanyImage(event: any): Promise<void> {
    const file: File = event.target.files[0];
    this.companyData.patchValue({ image: file });
    await this.uploadCompanyLogo();
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.companyImage = reader.result;
      };
    }
  }

  async uploadProfileImage() {
    try {
      const payload = {
        image: this.profileData.value.image,
      };
      const res = await this.service.updateUserProfileImage(payload);
      return res;
    } catch (error: any) {
      return {};
    }
  }

  async uploadCompanyLogo() {
    try {
      const payload = {
        image: this.companyData.value.image,
      };
      const res = await this.service.updateCompanyLogo(payload);
      return res;
    } catch (error: any) {
      return {};
    }
  }

  async onCompanySave(): Promise<void> {
    this.onSaveLoad = true;
    const { email, company_name, billingAddress, businessAddress, ABN, GST } =
      this.companyData.value;
    try {
      const payload = {
        name: this.profileData.value.name,
        abn: ABN,
        gst: GST === 'Yes' ? true : false,
        email,
        companyName: company_name,
        billingAddress: billingAddress?.address
          ? billingAddress?.address
          : this.userData?.billingAddress,
        businessAddress: businessAddress.address
          ? businessAddress?.address
          : this.userData?.businessAddress,
      };
      const data = await this.service.updateUserProfileData(payload);
      if (data) {
        this.onSaveLoad = false;
        this.successToast('Profile Updated Successfully');
        window.location.reload();
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.error.message);
    }
  }

  private async downloadAndExtractImages(imageGroups: any): Promise<any> {
    const headers = new HttpHeaders({
      Accept: 'application/zip',
    });

    console.log(imageGroups);
    const result: any = [];

    const payload = { ImagePaths: [imageGroups] };

    try {
      const response: any = await this.http
        .post(`${environment.baseUrl}/api/images/fetch`, payload, {
          headers,
          responseType: 'arraybuffer',
        })
        .toPromise();

      const zip = new JSZip();
      await zip.loadAsync(response);
      const imagePromises = Object.keys(zip.files).map(async (filename) => {
        const file = zip.files[filename];
        const blob = await file.async('blob');
        const url = URL.createObjectURL(blob);
        return url;
      });

      const images = await Promise.all(imagePromises);

      result.push({
        images: images,
      });
    } catch (error) {
      console.error(error);
    }

    return result;
  }

  onCancel(): void {
    window.history.back();
  }
}
