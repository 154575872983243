<div class="page-container catalogue-container">
  <p-toast />
  <div style="padding-top: 2rem">
    <p-tabView>
      <p-tabPanel header="Product Catalogue">
        <div class="header-container">
          <app-search-text
            (searchEvent)="searchSubject.next($event)"
            class="header"
          />
        </div>
        <div>
          <p-table
            [columns]="productCols"
            [value]="CatalogueList"
            [paginator]="true"
            [lazy]="true"
            (onPage)="pageChange($event)"
            dataKey="id"
            [rows]="currentPageSize"
            [first]="first"
            [totalRecords]="catalogueCount"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns">{{ col.header }}</th>
              </tr>

              <ng-container *ngIf="isLoading">
                <tr *ngFor="let _ of skeletonRows">
                  <th class="skeleton" *ngFor="let col of columns">
                    <p-skeleton></p-skeleton>
                  </th>
                </tr>
              </ng-container>
            </ng-template>

            <ng-template pTemplate="paginatorright">
              <p-dropdown
                [options]="[
                  { label: '10', value: 10 },
                  { label: '20', value: 20 },
                  { label: '50', value: 50 }
                ]"
                [(ngModel)]="currentPageSize"
                (onChange)="onPageSizeChange($event)"
              >
              </p-dropdown>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td>{{ rowData.model }}</td>
                <td>{{ rowData.manufacturer }}</td>
                <td>{{ rowData.deviceType }}</td>
                <td style="text-align: center">
                  <div (click)="addtoFav(rowData)">
                    <img
                      *ngIf="!rowData.isFavourite"
                      src="assets/unFav.icon.svg"
                    />
                    <img
                      *ngIf="rowData.isFavourite"
                      src="assets/Fav.icon.svg"
                    />
                  </div>
                </td>
                <td>
                  <img src="assets/eye-t.svg" />
                </td>
              </tr>
            </ng-template>

            <ng-template
              pTemplate="emptymessage"
              *ngIf="CatalogueList.length === 0"
            >
              <tr *ngIf="!isLoading">
                <td colspan="5">No Data found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Price">
        <div style="margin-top: 2rem">
          <p-table
            [columns]="priceCols"
            [value]="priceList"
            dataKey="id"
            editMode="row"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            [tableStyle]="{ 'min-width': '50rem' }"
            [rowsPerPageOptions]="[5, 10, 20]"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th>Model</th>
                <th>Manufacture</th>
                <th>Catagory</th>
                <th>From Date</th>
                <th>To Date</th>
                <th style="width: 10%; text-align: end">Cost Price</th>
                <th style="width: 10%; text-align: end">Margin %</th>
                <th style="width: 10%; text-align: end">Margin Amount</th>
                <th style="width: 10%; text-align: end">RRP</th>
                <th></th>
              </tr>

              <ng-container *ngIf="isLoading">
                <tr *ngFor="let _ of skeletonRows">
                  <th class="skeleton" *ngFor="let col of columns">
                    <p-skeleton></p-skeleton>
                  </th>
                </tr>
              </ng-container>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-rowData
              let-columns="columns"
              let-i="rowIndex"
              let-editing="editing"
            >
              <tr [pEditableRow]="rowData">
                <td>{{ rowData.model }}</td>
                <td>{{ rowData.manufacturer }}</td>
                <td>{{ rowData.deviceType }}</td>
                <td>
                  <img
                    src="assets/CalendarIcon.svg"
                    style="margin-right: 5px"
                  />
                  {{ rowData?.eligibility?.eligibleFrom | date : "yyyy-MM-dd" }}
                </td>
                <td>
                  <img
                    src="assets/CalendarIcon.svg"
                    style="margin-right: 5px"
                  />
                  {{ rowData?.eligibility?.eligibleTo | date : "yyyy-MM-dd" }}
                </td>
                <td style="width: 10%; text-align: end">
                  <p-cellEditor style="font-size: 0.9rem">
                    <ng-template pTemplate="input">
                      <p-inputNumber
                        [(ngModel)]="rowData?.eligibility.costPrice"
                        mode="decimal"
                        [minFractionDigits]="2"
                        [maxFractionDigits]="2"
                      />
                    </ng-template>
                    <ng-template
                      style="width: 10%; text-align: end"
                      pTemplate="output"
                    >
                      {{
                        rowData?.eligibility?.costPrice ?? 0 | currency : "USD"
                      }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td style="width: 10%; text-align: end">
                  <p-cellEditor style="font-size: 0.9rem">
                    <ng-template pTemplate="input">
                      <p-inputNumber
                        [(ngModel)]="rowData?.eligibility.margin"
                      />
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ rowData?.eligibility?.margin ?? 0 }} %
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td style="width: 10%; text-align: end">
                  {{
                    rowData?.eligibility?.marginAmount || 0 | currency : "USD"
                  }}
                </td>
                <td style="width: 10%; text-align: end">
                  {{ rowData?.eligibility?.rrp || 0 | currency : "USD" }}
                </td>
                <td>
                  <div style="display: flex">
                    <i
                      *ngIf="!editing"
                      class="pi pi-pencil"
                      style="color: #235aff"
                      pInitEditableRow
                    ></i>
                    <i
                      *ngIf="editing"
                      class="pi pi-check"
                      (click)="onRowPriceSave(rowData)"
                      pSaveEditableRow
                      style="color: #30a042; padding-right: 2rem"
                    ></i>
                    <i
                      *ngIf="editing"
                      class="pi pi-times"
                      pCancelEditableRow
                      style="color: #ff4342"
                    ></i>
                  </div>
                </td>
              </tr>
            </ng-template>

            <ng-template
              pTemplate="emptymessage"
              *ngIf="CatalogueList.length === 0"
            >
              <tr *ngIf="!isLoading">
                <td colspan="5">No Data found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-tabPanel>

      <p-tabPanel header="Service Catalogue">
        <div class="service-container">
          <div class="header-container">
            <app-button
              label="Add"
              (clickEvent)="handleAddDialog()"
              class="header"
            />
          </div>

          <p-table
            [columns]="serviceCols"
            dataKey="id"
            [value]="serviceList"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            [tableStyle]="{ 'min-width': '50rem' }"
            [rowsPerPageOptions]="[5, 10, 20]"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th>Service Item</th>
                <th style="text-align: end">Price</th>
                <th></th>
              </tr>

              <ng-container *ngIf="isLoading">
                <tr *ngFor="let _ of skeletonRows">
                  <th class="skeleton" *ngFor="let col of columns">
                    <p-skeleton></p-skeleton>
                  </th>
                </tr>
              </ng-container>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td>{{ rowData.serviceItem }}</td>
                <td style="text-align: end">
                  {{ rowData.price | currency : "USD" }}
                </td>
                <td style="text-align: center" (click)="op.toggle($event)">
                  <img src="assets/ActionMenu.svg" style="cursor: pointer" />
                  <p-overlayPanel #op>
                    <ng-template pTemplate="content">
                      <div
                        (mouseover)="handleHover(1)"
                        (click)="handleEdit(rowData)"
                        class="list-item"
                        [class.hover-bg-light-blue]="onHover === 1"
                        (mouseleave)="onHover = 0"
                      >
                        <i
                          class="pi pi-user-edit"
                          style="font-size: 1.3rem"
                        ></i>
                        <span style="font-size: 14px">Edit</span>
                      </div>

                      <div
                        (mouseover)="handleHover(2)"
                        (click)="handleDelete(rowData)"
                        class="list-item"
                        [class.hover-bg-light-red]="onHover === 2"
                        (mouseleave)="onHover = 0"
                      >
                        <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                        <span style="font-size: 14px">Delete</span>
                      </div>
                    </ng-template>
                  </p-overlayPanel>
                </td>
              </tr>
            </ng-template>

            <ng-template
              pTemplate="emptymessage"
              *ngIf="serviceList.length === 0"
            >
              <tr *ngIf="!isLoading">
                <td colspan="5">No Data found.</td>
              </tr>
            </ng-template>
          </p-table>

          <p-dialog
            header="Header"
            [(visible)]="serviceDialog"
            [modal]="true"
            [closable]="false"
            [style]="{ width: '35vw' }"
          >
            <ng-template pTemplate="header">
              <h3>{{ edit ? "Edit" : "Add" }}</h3>
            </ng-template>
            <ng-template pTemplate="content">
              <form class="dialog-form" [formGroup]="formData">
                <div class="grid-container">
                  <app-input
                    label="Service Item"
                    [control]="formData.get('serviceItem')"
                    [required]="true"
                  />
                  <app-number-input
                    [control]="formData.get('price')"
                    [useGrouping]="false"
                    label="Price"
                    inputId="minmaxfraction"
                    mode="decimal"
                    [minFractionDigits]="0"
                    [maxFractionDigits]="5"
                  />
                </div>
              </form>
            </ng-template>
            <ng-template pTemplate="footer">
              <app-button
                label="Cancel"
                (clickEvent)="closeDialog()"
                className="cancel-dialog"
                style="margin-right: 1rem"
              />
              <app-button
                *ngIf="!edit"
                label="Save"
                [disabled]="formData.invalid"
                (clickEvent)="onSave()"
                [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
              />
              <app-button
                *ngIf="edit"
                label="Update"
                [disabled]="formData.invalid"
                (clickEvent)="onUpdate()"
                [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
              />
            </ng-template>
          </p-dialog>

          <p-dialog
            header="Header"
            [(visible)]="deleteDialog"
            [modal]="true"
            [closable]="false"
            [style]="{ width: '32vw' }"
          >
            <ng-template pTemplate="header"> </ng-template>
            <ng-template pTemplate="content">
              <h1>Are you sure you want to delete this Service Item ?</h1>
            </ng-template>
            <ng-template pTemplate="footer">
              <app-button
                label="Cancel"
                (clickEvent)="closeDialog()"
                className="cancel-dialog"
                style="margin-right: 1rem"
              />
              <app-button
                label="Delete"
                (clickEvent)="onDeleteService()"
                [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
              />
            </ng-template>
          </p-dialog>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
