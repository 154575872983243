import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';

@Injectable({ providedIn: 'root' })
export class TaskService {
  constructor(private api: ApiService) {}

  async fetchTaskData(): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.get(`api/tasks`));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchInstallerTaskData(): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get(`api/taskservicecatalogue/installer`)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createInstallerTaskData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.post(`api/taskservicecatalogue`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateInstallerTaskData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.put(`api/taskservicecatalogue`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
